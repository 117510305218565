<template>
  <div class="container-fluid userman-nopadd">
    <Header />
    <div class="mx-auto mt-1 content-outer-container usermanual">
      <div class="mainContainer">
        <div class="header">
          <div class="left">
            <img src="@/assets/images/Cooking-book.svg" alt="" />
            <span>{{ lbl['main-menu-usermanul'] }}</span>
          </div>
          <div>
            <a class="outlineprimary" @click="downloadPDF">
              <img
                class="mr-2"
                :src="require('@/assets/images/Download.svg')"
                alt=""
              />{{ lbl['user-permissions-download'] }}
            </a>
          </div>
        </div>
        <div class="pdffiles">
          <template>
            <a-skeleton :loading="!isshowpdf" />
            <a-skeleton :loading="!isshowpdf" />
            <a-skeleton :loading="!isshowpdf" />
            <a-skeleton :loading="!isshowpdf" />
          </template>
          <pdf
            v-for="i in numPages"
            :key="i"
            :v-if="isshowpdf"
            :src="src"
            :page="i"
            style="display: inline-block; width: 100%"
          ></pdf>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'
import Vue from 'vue'
import pdf from 'vue-pdf'
export default {
  name: 'usermanual',
  mixins: [Mixin],
  components: {
    Header,
    pdf,
  },
  data: function () {
    return {
      data: '',
      locale: Locale,
      numPages: undefined,
      isshowpdf: false,
    }
  },
  computed: {
    currentLocale() {
      return Locale.getLocaleShort().toUpperCase()
    },
  },
  mounted() {
    this.handleFooter(true)
    this.data =
      Vue.bzbsConfig.bzbsBlobUrl +
      `/config/crmplus/usermanual/CRM%20Plus_Usermanual_${this.currentLocale}.pdf` +
      `?v=` +
      new Date().getTime()

    var loadingTask = pdf.createLoadingTask(this.data)
    this.src = loadingTask
    this.src.promise.then(pdf => {
      this.isshowpdf = true
      this.numPages = pdf.numPages
    })
  },
  methods: {
    downloadPDF() {
      const link = document.createElement('a')
      link.href = this.data
      link.download = 'Usermanual.pdf'
      link.dispatchEvent(new MouseEvent('click'))
    },
  },
}
</script>
<style lang="scss" scoped>
.usermanual {
  .mainContainer {
    width: 100%;
    margin-bottom: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 30px;
      .left {
        color: #212121;
        font-size: 34px;
        border-bottom: 1px solid #f5f5f5;
        span {
          margin-left: 16px;
        }
        img {
          position: relative;
          top: -5px;
        }
      }
    }
    .pdffiles {
      width: 100%;
      padding: 30px;
    }
  }
}
</style>
<style lang="scss">
.container-fluid.userman-nopadd {
  padding-bottom: 10px !important;
}
</style>
